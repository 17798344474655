<template>
  <div class="o-login">
    <div class="o-login__card">

      <b-img
        v-if="!isIts()"
        src="../assets/logo-hdemia.jpg"
        fluid-grow
        alt="foppa group"
        class="mb-5"
      ></b-img>

      <b-img v-if="isIts()"
        src="../assets/logo-its.jpg"
        fluid-grow
        alt="ITS"
        class="mb-5"
      ></b-img>

      <b-form-group id="input-group-1" label="" label-for="" description="">
        <b-form @submit="onSubmit" @reset="onReset">
          <label class="sr-only" for="form-input-name">Email</label>
          <b-form-input
            id="form-input-name"
            class="mt-2"
            type="text"
            placeholder="Email"
            v-model="user.username"
            required
          ></b-form-input>

          <label class="sr-only" for="inline-form-input-name">Password</label>
          <b-form-input
            id="inline-form-input-name"
            class="mt-2"
            type="password"
            placeholder="Password"
            v-model="user.password"
            required
          ></b-form-input>

          <b-button block type="submit" variant="primary" class="mt-2"
            >Accedi</b-button
          >
          <!--  <div class="mt-4">
            <router-link :to="{ name: 'rePassword' }"
              >Recupera password</router-link
            >
          </div> -->
        </b-form>
      </b-form-group>
    </div>
  </div>
</template>
<script>

import { mapActions } from "vuex";
import environment from "../environment";
export default {
  name: "AppLogin",
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions(["users/login"]),

    async onSubmit(event) {
      event.preventDefault();
      try {
        await this.$store.dispatch("login", this.user).then(() => {
          if (localStorage.getItem("token") !== null) {
            if (
              localStorage.getItem("SET_LOGGED") ===
              localStorage.getItem("token")
            ) {
              console.log("Dashboard");
              this.$router.push({ name: "Dashboard" });
            } else {
              /* this.$router.push({ name: "Login" }); */
              this.toast(
                `Credenziali errate`,
                `Email / Password errate`,
                "warning",
                true
              );
            }
          } else {
            this.toast(
              `Credenziali errate`,
              `Email / Password errate`,
              "warning",
              true
            );
          }
        });
      } catch (e) {
        console.log({ message: "Some error login" });
      }
    },

    toast(title, message, variant, append = false) {
      this.counter++;
      this.$bvToast.toast(message, {
        title: title,
        toaster: "b-toaster-bottom-right",
        solid: true,
        variant: variant,
        appendToast: append,
      });
    },

    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.user.username = "";
      this.user.password = "";

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },


    isIts() {
      return environment.useItsLegacy;
    }

    /*  login(event) {
      event.preventDefault();
      //simulo login, cambio stato logged in true e rimando alla home
      this.$store.dispatch("login", this.user);
      this.$router.push({ name: "Dashboard" });
    }, */
  },
};
</script>
