import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from "bootstrap-vue";
import { VueEditor } from "vue2-editor";

import "./styles/_global.scss";

Vue.use(VueEditor);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

Vue.use(Vuex);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
