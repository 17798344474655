<template>
  <div class="o-navbar">
    <b-navbar toggleable="xl" variant="light" small="true" sticky>
      <b-navbar-brand>

       <img v-if="!isIts()"
          src="../../assets/logo-hdemia.jpg"
          class="d-inline-block align-top o-navbar_img"
          alt="foppa group"
        />
        <img v-if="isIts()"
          src="../../assets/logo-its.jpg"
          class="d-inline-block align-top o-navbar_img"
          alt="ITS"
        />
      </b-navbar-brand>

      <b-navbar-toggle
        target="nav-collapse"
        toggleable="lg"
        is-nav
      ></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-nav class="ml-auto">
          <b-nav-item :to="{ name: 'Dashboard' }">Dashboard</b-nav-item>
          <b-nav-item :to="{ name: 'TipologiaAttivita' }"
            >Tipologia attività</b-nav-item
          >
          <b-nav-item :to="{ name: 'Attivita' }">Attività</b-nav-item>
          <b-nav-item :to="{ name: 'Contatti' }">Contatti</b-nav-item>
          <b-nav-item :to="{ name: 'Importazioni' }">Importazioni</b-nav-item>
          <b-nav-item
            :to="{ name: 'Logout' }"
            link-classes="text-danger o-navbar__logout"
            >Logout</b-nav-item
          >
        </b-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>

import environment from "../../environment";

export default {
  name: "AppHeader",
  methods: {
    isIts() {
      return environment.useItsLegacy;
    }
  }
};
</script>
